<!--
 * @Author: your name
 * @Date: 2020-10-26 15:19:06
 * @LastEditTime: 2020-10-26 16:14:27
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \trunk\src\pages\equipmentState\equipmentState.vue
-->
<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <el-tabs v-model="equipmentActiveName" @tab-click="handleClick" class="bgFFF">
        <el-tab-pane label="设备维度" name="equipmentChild">
          <equipmentcomponent v-if="equipmentActiveName == 'equipmentChild'"></equipmentcomponent>
        </el-tab-pane>
        <el-tab-pane label="道路维度" name="parkingChild">
          <parkingequipment
            v-if="equipmentActiveName == 'parkingChild'"
          ></parkingequipment>
        </el-tab-pane>
        <el-tab-pane label="设备掉线记录" name="equipmentDropsChild">
          <equipmentdrops v-if="equipmentActiveName == 'equipmentDropsChild'"></equipmentdrops>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import equipmentcomponent from "./vedioEquipmentList/vedioEquipmentList";
import parkingequipment from "./parkingEquipment/parkingEquipment";
import equipmentdrops from "./equipmentDrops/equipmentDrops";
export default {
  name: "videoEquipmentList",
  data() {
    return {
      equipmentActiveName: "equipmentChild",
    };
  },
  methods: {
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    searchData() {
      // if (this.formInline.parkId != '') {
      //   this.$refs.parkInput.setShowVal(this.formInline.parkName)
      //   this.getReport();
      // } else {
      //   this.$refs.parkInput.setShowVal('')
      //   this.getReport();
      // }
    },
    // 停车场收入报表 查询
  },
  components: {
    equipmentcomponent,
    parkingequipment,
    equipmentdrops,
  },
  beforeDestroy() {},
  computed: {},
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.fullPath == "/equScene") {
        vm.equipmentActiveName = "parkingChild";
      }
    });
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
>>>.el-tabs__header
    padding-left 0px
    margin: 0
    .el-tabs__nav-wrap {
      height: 40px
      .el-tabs__nav-scroll {
      }
      .el-tabs__nav {
        height 40px
        .el-tabs__item {
          padding: 9px 16px
          height 100%
        }
      }
    }
.content
  overflow: hidden;
  border-radius: 12px
  .searchWrapper
    overflow: hidden;
    // padding: 22px 22px 0;
    // margin-bottom: 20px;
  .list
    margin-top: 20px;
    .el-col
      padding-bottom 18px
      height: 320px;
      .grid-content
        padding-top: 15px;
        height 100%
      h2
        font-size: 18px;
        text-align: center;
</style>
