<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form
          :inline="true"
          status-icon
          label-position="right"
          label-width="96px"
          :model="formInline"
          ref="formInline"
          class="demo-form-inline"
        >
          <div class="search_box_title">
            {{ $t("searchModule.Query_Table") }}
          </div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Road_name')">
                <el-autocomplete
                  class="inline-input"
                  v-model="modelvalue1"
                  :fetch-suggestions="querySearchAsync1"
                  placeholder="请输入道路名称"
                  value-key="parkName"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <el-select
                  v-model.trim="formInline.operationId"
                  filterable
                  size="15"
                >
                  <el-option label="全部" value></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label="$t('searchModule.Equipment_status')"
                prop="equStatus"
              >
                <el-select v-model.trim="formInline.equStatus" size="15">
                  <el-option label="全部" value>全部</el-option>
                  <el-option label="正常" value="1"></el-option>
                  <el-option label="异常" value="2"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  pageNum = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t("button.search") }}</el-button
              >
            </div>
          </div>
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table v-loading="loading" :data="parkingData" style="width: 100%">
          <el-table-column
            align="center"
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            v-for="item in parkingCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            :label="$t('searchModule.Equipment_status')"
            width
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>
                <font
                  :class="{ red: scope.row.isOk == 1 }"
                  v-if="scope.row.isOk == 1"
                  >{{ $t("list.abnormal") }}</font
                >
                <font v-if="scope.row.isOk == 0">{{ $t("list.normal") }}</font>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('list.operation')"
            align="center"
            width="80"
          >
            <template slot-scope="scope">
              <el-button
                style="padding: 0"
                type="text"
                size="small"
                @click="showDetail(scope.row)"
                v-if="
                  $route.meta.authority.tabs.parkEquipmentStatiscs.button.detail
                "
                >详情</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import infoTable from "components/infoTable/infoTable";
export default {
  name: "equipmentState",
  data() {
    return {
      pageNum: 1,
      pageSize: 15,
      page: 1,
      total: 0,
      modelvalue1: "",
      tenantList: [],
      parkingData: [],
      formInline: {
        parkId: "",
        operationId: "",
        equStatus: "",
      },
      // 道路维度列表
      parkingCols: [
        {
          prop: "parkName",
          label: this.$t("list.location_road"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Belonging_operator"),
          width: "",
        },
        {
          prop: "normal",
          label: this.$t("list.Normal_number_of_devices"),
          width: "",
        },
        {
          prop: "abnormal",
          label: this.$t("list.Number_of_abnormal_devices"),
          width: "",
        },
      ],
      loading: false,
    };
  },
  methods: {
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    querySearchAsync1(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: this.modelvalue1,
            slaveRelations: "0,1,2",
            commercialStatus: "2,3",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            //  this.parkData = res.value.list;
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    showDetail(row) {
      this.$router.push({
        path: "/parkingequipmentDetail",
        query: row,
      });
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    /* 运营商名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t("pop_up.Tips"), {
            confirmButtonText: this.$t("pop_up.Determine"),
          });
        }
      });
    },
    searchData() {
      if (!this.modelvalue1) {
        this.formInline.parkId = "";
      }
      this.$axios
        .get("/acb/2.0/equipmentStatus/getEquipmentStatusByPark", {
          data: {
            parkId: this.formInline.parkId,
            operationId: this.formInline.operationId,
            equStatus: this.formInline.equStatus,
            pageNum: this.pageNum,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.parkingData = res.value ? res.value.list : [];
            this.total = res.value.total * 1 || 0;
          } else {
            this.$alert(res.desc, this.$t("pop_up.Tips"), {
              confirmButtonText: this.$t("pop_up.Determine"),
            });
          }
        });
    },
    // 停车场收入报表 查询
  },
  components: {
    //    infoTable
  },
  mounted() {
    this.searchData();
    this.getTenantList();
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.pagerWrapper {
  text-align: right;
  margin-top: 18px;
  font-size: 12px;
  // .el-pagination {
  // padding-top: 20px;
  // text-align: right;
  // }
}

.content {
  overflow: hidden;

  .list {
    margin-top: 20px;

    .el-col {
      padding-bottom: 18px;
      height: 320px;

      .grid-content {
        padding-top: 15px;
        height: 100%;
      }

      h2 {
        font-size: 18px;
        text-align: center;
      }
    }
  }
}
</style>
