var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page1" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c(
          "el-tabs",
          {
            staticClass: "bgFFF",
            on: { "tab-click": _vm.handleClick },
            model: {
              value: _vm.equipmentActiveName,
              callback: function ($$v) {
                _vm.equipmentActiveName = $$v
              },
              expression: "equipmentActiveName",
            },
          },
          [
            _c(
              "el-tab-pane",
              { attrs: { label: "设备维度", name: "equipmentChild" } },
              [
                _vm.equipmentActiveName == "equipmentChild"
                  ? _c("equipmentcomponent")
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "道路维度", name: "parkingChild" } },
              [
                _vm.equipmentActiveName == "parkingChild"
                  ? _c("parkingequipment")
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "el-tab-pane",
              { attrs: { label: "设备掉线记录", name: "equipmentDropsChild" } },
              [
                _vm.equipmentActiveName == "equipmentDropsChild"
                  ? _c("equipmentdrops")
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }