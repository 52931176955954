<!--
	作者：jyl
	时间：2017-07-26
	描述：商户信息管理页面
-->
<template>
  <div class="page1">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <div class="searchWrapper" @keydown.enter.prevent="searchData">
        <el-form :inline="true" label-position="right" :model="formInline" class="demo-form-inline">
          <div class="search_box_title">{{ $t('searchModule.Query_Table') }}</div>
          <div class="col_box">
            <div class="col_left">
              <el-form-item :label="$t('searchModule.Equipment_name')">
                <el-input
                  v-model.trim="formInline.equipmentName"
                  maxlength="20"
                  placeholder="请输入设备名称"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Equipment_number')">
                <el-input
                  v-model.trim="formInline.equipmentCode"
                  maxlength="30"
                  placeholder="请输入设备编号"
                ></el-input>
              </el-form-item>
              <el-form-item label="对应杆位号">
                <el-input
                  maxlength="20"
                  size="12"
                  v-model.trim="formInline.barCode"
                  placeholder="请输入对应杆位号"
                ></el-input>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Equipment_type')">
                <el-select
                  v-model.trim="formInline.equipmentType"
                  style="width: 150px"
                  placeholder=""
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.desc"
                    :value="value.code"
                    :key="value.code"
                    v-for="value in equipmentTypeList"
                  ></el-option>
                  <!-- <el-option label="PDA设备" value="1"></el-option>
              <el-option label="MS设备" value="2"></el-option>
              <el-option label="枪机-半封闭" value="3"></el-option>
              <el-option label="枪球机-平行" value="4"></el-option>
              <el-option label="违停球" value="5"></el-option>
              <el-option label="垂停设备" value="6"></el-option>
              <el-option label="平行多枪设备" value="7"></el-option>
              <el-option label="中位视频设备" value="8"></el-option> -->
                </el-select>
              </el-form-item>
              <el-form-item label="所在道路">
                <el-autocomplete
                  class="inline-input"
                  v-model="modelvalue1"
                  :fetch-suggestions="querySearchAsync1"
                  placeholder="请输入所在道路"
                  value-key="parkName"
                  :trigger-on-focus="false"
                  @select="handleSelect"
                ></el-autocomplete>
              </el-form-item>
              <el-form-item :label="$t('searchModule.Belonging_operator')">
                <el-select v-model.trim="formInline.operationId" filterable size="15">
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    :label="value.operationName"
                    :value="value.operationId"
                    :key="value.operationId"
                    v-for="value in tenantList"
                  ></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col_right">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="
                  page = 1;
                  searchData();
                "
                :loading="loading"
                >{{ $t('button.search') }}</el-button
              >
            </div>
          </div>
          <div class="col_box_boder" v-if="$route.meta.authority.button.add"></div>
          <div class="col_box h44" v-if="$route.meta.authority.button.add">
            <div class="col_left">
              <router-link :to="{ path: '/equipmentAE' }"
                ><el-button
                  v-if="$route.meta.authority.button.add"
                  type="success"
                  icon="el-icon-plus"
                  >{{ $t('button.addto') }}</el-button
                ></router-link
              >
            </div>
            <div class="col_right mbd4"></div>
          </div>

          <!-- <el-form-item> -->

          <!-- <el-button type="danger" icon="el-icon-delete" @click="resetForm()"
              >{{ $t('button.reset') }}</el-button
            > -->
          <!-- </el-form-item> -->
        </el-form>
      </div>
      <!--列表区域-->
      <div class="tableWrapper bgFFF paddingB10">
        <el-table
          header-cell-class-name="header-call-style"
          v-loading="loading"
          :data="tableData"
          style="width: 100%; font-size: 12px"
        >
          <el-table-column
            :prop="item.prop"
            :label="item.label"
            :width="item.width"
            align="center"
            v-for="item in tableCols"
            :key="item.prop"
            :formatter="item.formatter"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
      <!-- <equipmentBox
        :infoSize="4"
        :currentbasicNumber="basicNumber"
        :detailFlag="dialogVisible"
        :infoOperating="Operatingdata"
        :infoDetail="basic"
        @close="dialogVisible = false"
      ></equipmentBox> -->
    </div>
  </div>
</template>

<script>
// import equipmentBox from "./vedioEquipmentList-AE";
export default {
  name: "equipmentList",
  data() {
    return {
      equipmentTypeList: [],
      basicNumber: [],
      dialogVisible: false,
      modelvalue: "",
      modelvalue1: "",
      index: 0,
      equipmentStateList: [],
      total: 0,
      pageSize: 15,
      page: 1,
      loading: false,
      detailFlag: false,
      // showState: false,
      temParkData: {},
      areaList: [],
      parkList: [],
      berthList: [],
      tenantList: [],
      tableCols: [
        {
          prop: "equipmentName",
          label: this.$t("list.Equipment_name"),
          width: "",
        },
        {
          prop: "equipmentCode",
          label: this.$t("list.Equipment_number"),
          width: "",
        },
        {
          prop: "equipmentType",
          label: this.$t("list.Equipment_type"),
          width: "",
          formatter: (row, column, cellValue, index) => {
            let equipmentType = this.equipmentTypeList.find((v) => v.code === cellValue);
            return equipmentType.desc;
          },
        },
        {
          prop: "parkName",
          label: "所在道路",
          width: "",
        },
        {
          prop: "barCode",
          label: this.$t("list.Correspondin_pole_position_number"),
          width: "",
        },
        {
          prop: "areaName",
          label: this.$t("list.region"),
          width: "",
        },
        {
          prop: "operationName",
          label: this.$t("list.Belonging_operator"),
          width: "",
        },
        {
          prop: "createdTime",
          label: this.$t("list.Offline_time"),
          width: "170",
        },
      ],
      tableData: [],
      basic: [],
      Operatingdata: [],
      formInline: {
        barCode: "",
        page: 1,
        pageSize: 15,
        equipmentName: "",
        equipmentCode: "",
        // equipmentState: "1",
        // equipmentStatus: "",
        equipmentType: "",
        operationId: "",
        state: "",
        parkId: "",
      },
    };
  },
  methods: {
    // stateShow() {
    //   if (this.formInline.equipmentStatus == 1) {
    //     this.showState = true;
    //   } else {
    //     this.showState = false;
    //     this.formInline.equipmentState = "";
    //   }
    // },
    resetForm() {
      this.modelvalue = "";
      this.modelvalue1 = "";
      // this.showState = false;
      this.formInline = {
        barCode: "",
        equipmentName: "",
        equipmentCode: "",
        // equipmentState: "1",
        // equipmentStatus: "",
        equipmentType: "",
        operationId: "",
        state: "",
        parkId: "",
      };
      // this.stateShow();
    },
    handleCommand(cmd, data) {
      if (cmd == "a") {
        this.dialogVisible = true;
        this.basicinformation(data);
        this.Operatinginformation(data);
      }
      if (cmd == "b") {
        this.$router.push({
          path: "/equipmentAE",
          query: data,
        });
      }
    },
    querySearchAsync1(queryString, cb) {
      this.$emit("valueChange", "");
      queryString = queryString.replace(/\s/g, "");
      let url = "/acb/2.0/systems/loginUser/getParkName";
      this.$axios
        .get(url, {
          data: {
            page: 1,
            pageSize: 15,
            parkName: this.modelvalue1,
            slaveRelations: "0,1",
            commercialStatus: "2,3",
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.temParkData = res.value.list.length
              ? res.value.list[0]
              : { parkName: "", parkId: "" };
            this.formInline.parkId = "";
            //  this.parkData = res.value.list;
            cb(res.value.list);
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    handleSelect(item) {
      this.formInline.parkId = item.parkId;
    },
    // 设备基本信息
    basicinformation(data) {
      this.basic = [];
      this.basicNumber = [];
      this.$axios
        .get("/acb/2.0/equipment/detail", {
          data: {
            equipmentId: data.equipmentId,
          },
        })
        .then((res) => {
          // console.log(res)
          if (res.state == 0) {
            this.basic = res.value;
            this.basicNumber = res.value.berthList;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    // 设备运营信息
    Operatinginformation(data) {
      this.Operatingdata = [];
      this.$axios
        .get("/acb/2.0/equipmentStatus/getEquipmentAndStatus", {
          data: {
            equipmentId: data.equipmentId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            this.Operatingdata = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    /* 运营商名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    indexMethod(index) {
      return index + 1;
    },
    // 获取设备状态
    getOptions() {
      let url = "/acb/2.0/equipment/getAddOption";
      this.$axios.get(url).then((res) => {
        this.loading = false;
        if (res.state == 0) {
          this.equipmentTypeList = res.value.equipmentType;
        } else {
          this.$alert(res.desc);
        }
      });
    },
    // 分页处理
    handleCurrentChange(val) {
      this.page = val;
      this.searchData();
    },
    // 搜索
    searchData() {
      this.tableData = [];
      if (this.modelvalue1 == "") {
        this.formInline.parkId = "";
      }
      // if (this.formInline.equipmentState == 0) {
      //   this.formInline.equipmentStatus = 1
      // }
      this.loading = true;
      let url = "/acb/2.0/equipment/queryEquipmentStatusRecordPage";
      this.$axios
        .get(url, {
          data: {
            ...this.formInline,
            page: this.page,
            pageSize: this.pageSize,
          },
        })
        .then((res) => {
          // console.log(res)
          this.loading = false;
          if (this.tableData == "") {
            this.total = 0;
          }
          if (res.state == 0) {
            this.tableData = res.value.list;
            this.total = res.value.total * 1;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
  },
  components: {
    // equipmentBox,
  },
  beforeDestroy() {
    sessionStorage.searchData = JSON.stringify(this.formInline);
    sessionStorage.searchFlag = 1;
  },
  activated() {
    this.getOptions();
    this.searchData();
    this.getTenantList();
  },
  created() {
    this.getOptions();
    this.searchData();
    this.getTenantList();
    // this.stateShow();
  },
  mounted() {},
  computed: {},
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.content
  overflow: hidden;
  .searchWrapper
    overflow: hidden;
    // padding: 22px 22px 0;
    // margin-bottom: 20px;
  .pagerWrapper
    text-align: right;
    margin-top: 18px;
    font-size: 12px;
    .export
      font-size: 12px;
      .iconfont
        margin-right: 0;
.detail
  width: 61.4%;
  height: 82.9%;
  position: absolute;
  top: 8.6%;
  left: 20.1%;
  background: #FFFFFF;
  box-shadow: 0 0 4px 1px rgba(128,145,165,0.30);
  border-radius: 3px;
  z-index: 1000;
  box-sizing: border-box;
  padding: 20px 0;
  .title
    position: absolute;
    background: #3F4A56;
    border-radius: 3px 3px 0 0;
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 12px;
    box-sizing: inherit;
    z-index: 100;
    .el-icon-close
      float: right;
      margin-top: 14px;
  .info
    margin-top: 20px;
    padding: 2.1%;
    height: 100%;
    overflow: auto;
    box-sizing: inherit;
.mask
  background: rgba(49,53,65,0.60);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
</style>
